import { GroupedTemplates } from 'pages/Creator/models/FacebookCreationTemplate';
import { FacebookCreationTemplate } from 'pages/Creator/models/FacebookCreationTemplate';
import LinkData from 'pages/Creator/models/LinkData';
import {
  gatherArrayValuesInNestedObj,
  getNodeAtLastArrayItem,
} from 'utils/transformObj';

function articleAbbreviation(articleType: 'Article' | 'Slideshow' | 'Quiz') {
  switch (articleType) {
  case 'Slideshow':
    return ' | SLIDE';
  case 'Quiz':
    return ' | QUIZ';
  default:
    return '';
  }
}

export function formatTemplates(
  templates: FacebookCreationTemplate[],
  linkData: LinkData,
  articleType: 'Article' | 'Slideshow' | 'Quiz',
) {
  const isNonArticle = articleType !== 'Article';
  const postClassifier = articleAbbreviation(articleType);

  return templates
    .filter(template => {
      if (isNonArticle) {
        return (
          template.name !== 'vDA_BROAD_AEMV2 >75 RPM' &&
          template.name !== 'vDA_BROAD_AEMV2 >75 RPM | LC'
        );
      } else {
        return (
          template.name !== 'vDA_BROAD_AEMV2 >100 RPM' &&
          template.name !== 'vDA_BROAD_AEMV2 >100 RPM | LC' &&
          template.name !== 'vDA_BROAD_AEMV2 >80 RPM | BC' &&
          template.name !== 'vDA_BROAD_AEMV2 >90 RPM | BC' &&
          template.name !== 'vDA_BROAD_AEMV2 >100 RPM | BC'
        );
      }
    })
    .map((template, index) => {
      const id = index;
      const userOS = template.userOS ? ` | ${template.userOS[0]}` : '';
      let campaignName = `${linkData.slug} | ${template.name}`;
      let bid = null;

      if ('bidAmount' in template) {
        bid =
          isNonArticle && 'slideshowBidAmount' in template
            ? template.slideshowBidAmount
            : template.bidAmount;

        campaignName = `${campaignName.replace(
          /\s*\|\s*/,
          `${userOS} | `,
        )} $${bid!.toFixed(2)}`;
      } else if ('bidConstraints' in template) {
        campaignName = `${campaignName} | ${template.bidConstraints!.toFixed(
          2,
        )}`;
      }

      if (template.promotedObject.pixel_rule) {
        template.promotedObject.pixel_rule = template.promotedObject.pixel_rule.replace('$ContentType$', articleType);        
      }

      return {
        ...template,
        id,
        campaignName: `${campaignName}${postClassifier}`,
        ogCampaignName: `${campaignName}${postClassifier}`,
        title: linkData.title,
        caption: linkData.caption,
        ...(bid && { bidAmount: bid }),
      };
    });
}

export function groupTemplatesByHeaders(templates: FacebookCreationTemplate[]) {
  const groupedTemplates: GroupedTemplates = {};

  templates.forEach(template => {
    const {
      bidStrategy,
      bidConstraints,
      countries,
      countryGroups,
      userOS,
      rpm,
      id,
    } = template;

    let countryKey: string;
    if (countries) {
      countryKey = countries.length === 1 ? countries[0] : 'Non US';
    } else if (countryGroups) {
      countryKey = countryGroups.includes('Worldwide') ? 'WW' : 'Non US';
    } else {
      countryKey = 'Unknown';
    }

    groupedTemplates[bidStrategy] = groupedTemplates[bidStrategy] || {};
    groupedTemplates[bidStrategy][countryKey] =
      groupedTemplates[bidStrategy][countryKey] || {};
    const osKey = userOS ? userOS[0] : 'Unspec OS';
    groupedTemplates[bidStrategy][countryKey][osKey] =
      groupedTemplates[bidStrategy][countryKey][osKey] || {};

    const rpmAndBidConstraint = bidConstraints
      ? `${rpm} ${bidConstraints}`
      : rpm;
    groupedTemplates[bidStrategy][countryKey][osKey][rpmAndBidConstraint] =
      groupedTemplates[bidStrategy][countryKey][osKey][rpmAndBidConstraint] ||
      [];
    groupedTemplates[bidStrategy][countryKey][osKey][rpmAndBidConstraint].push(
      id,
    );
  });

  return groupedTemplates;
}

export function getSelectedTemplates(
  selectedGroups: string[],
  groupedTemplates: GroupedTemplates,
): string[] {
  const selectedGroup = getNodeAtLastArrayItem(
    selectedGroups,
    groupedTemplates,
  );
  if (Array.isArray(selectedGroup)) return selectedGroup;
  return gatherArrayValuesInNestedObj(selectedGroup);
}
